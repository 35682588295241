import React from 'react';
import { ReactComponent as MyIcon } from '../../icons/backArrow.svg';
import { useChatbotContext } from '../../context/ChatGlobalContext';


type HeaderProps = {
    title: string;
    onClick?: () => void; // Optional prop to add a custom button on the header.  // This prop will be called when the close button is clicked.  // If not provided, the default close button will be displayed.  // Note: If this prop is provided, the onClose prop should not be provided.  // If both props are provided, the onClose prop will be ignored.  // The onClick prop is useful for adding a custom button on the header.  // For example,
};

const Header: React.FC<HeaderProps> = ({ title, onClick }) =>{
    const { botState } = useChatbotContext();

    const customStyle ={
        backgroundColor: botState?.themeColor
    }

    return (
    <div style={customStyle} className="bg-gray-800 text-white p-4 flex items-center rounded-t-lg">
        <div className='flex justify-center size-8'>
        <button className="text-white size h-full text-2xl" onClick={onClick}>
        <MyIcon/>
        </button>
        </div>
        <span className="text-lg font-semibold pl-3">{title}</span>
    </div>
);
}
export default Header;

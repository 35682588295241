import React, { useEffect, useState } from "react";
import StartConversation from "../StartConversation/StartConversation";
import { useChatbotContext } from "../../context/ChatGlobalContext";
import { DEFAULT_LOGO_FOR_WEBCHAT, DEFAULT_USER_AVATAR } from "../../utils/constants";
import { getRelativeTime, getUserName, truncateText } from "../../utils/helper";
import Header from "../Header/Header";
import './Home.css';
import { useNavigate } from "react-router-dom";
import DynamicContentRenderer from "../shared/DynamicContentRenderer";

type HomeProps = {
  nextStep: (text?:string) => void;
};

type ButtonType = "home" | "messages";

const Home: React.FC<HomeProps> = ({ nextStep }) => {
  const { botState ,socket } = useChatbotContext(); // Use the context




  let welcomeText = botState?.welcomePrompt?.replace("@user@" ,getUserName())
  const truncatedText = welcomeText
    ? truncateText(welcomeText, 100)
    : "";
  const [active, setActive] = useState<ButtonType>("home");
  const navigate = useNavigate();

  const customStyle = {
    backgroundColor:botState?.themeColor
  }

  const [conversations ,setConversations] = useState([]);

  const handleClick = (button: ButtonType) => {
    setActive(button);
    getConversationList();
  };

  useEffect(() => {
    if (!socket) return;

    socket.on('connect' ,getConnection)

    // Listen for incoming messages from the bot
    socket.on("conversations-list", getConversations);
    // Clean up on component unmount
    return () => {
      socket.off("conversations-list", ()=>{});
    };
  }, [socket]);




  const getConversations = (msg:any) => {
    if(msg.conversations){
      setConversations(msg.conversations);
    }
  }


  useEffect(() => {
    getConversationList();
  }, []);


  const getConnection = () => {
    console.log('Connected to server');
  }


  const getConversationList = () => {
    if(socket){
      const session = JSON.parse(localStorage.getItem('session') as string);
      if(session){
        socket.emit('get-conversations', { session, from : socket.id } );
      }
    }
  }

  const handleNavigation = (conversation:any) => {
    if(conversation._id){
      localStorage.setItem('conversationId', conversation._id); 
      navigate('/chat');
    }
  }

  const openConversation = () => {
    localStorage.removeItem('conversationId'); 
    nextStep('')
  }

  return (
    <div className="flex flex-col h-full bg-gray-100">
      {active === "home" && (
        <>
          <div style={customStyle} className="bg-gray-900 text-white rounded-t-lg p-4 flex-grow flex flex-col justify-between max-h-100">
            <div className="flex items-center space-x-2">
              <div className=" rounded-full p-1">
                <img
                  src={botState.logo || DEFAULT_LOGO_FOR_WEBCHAT}
                  alt="IT Support"
                  className="h-8 w-8"
                />
              </div>
              <span className="text-sm">{botState?.name || "IT Support"}</span>
            </div>
            <h2 className="text-2xl font-semibold mt-4">{truncatedText}</h2>
          </div>

          <div className="flex-grow overflow-auto bg-white p-4">
            <ul>
             { botState?.quickPrompt?.map((el:any)=>(<li key={el._id} className="mb-2">
                <button
                  className="w-full text-left bg-slate-50 p-4 rounded-md"
                  aria-label="License Help"
                  onClick={()=>nextStep(el?.prompt)}
                >
                  {el.title}
                </button>
              </li>))
              }
        
               
            </ul>
              <div style={customStyle} className="start_conversation_home">
              <StartConversation
                  onClick={()=>openConversation()}  
                />
              </div>

          </div>
        </>
      )}

      {active === "messages" && (
        <div className="flex-grow bg-white " >
          <Header title="Messages" onClick={() => handleClick("home")} />
          {conversations?.length === 0 &&<p className="text-gray-500 mt-2">
            You have no Previous messages. Start a conversation with us.
          </p>}
            
          <ul className="message-list">
            {conversations?.length>0 &&conversations?.map((conversation: any) => (
              <li className="message-item" key={conversation?.conversationId} onClick={()=>handleNavigation(conversation)}>
                <div className="message-icon icon-license">
                {conversation.chat.messageType === 'bot' ?
               <img
               src={botState.logo || DEFAULT_LOGO_FOR_WEBCHAT}
               alt="IT Support"
               className="h-8 w-8"
             />:<><img  alt="user" src={DEFAULT_USER_AVATAR} /></>  
              }
                </div>
                <div className="message-content">
                  <div className="message-title">
                    {conversation.chat.messageType === 'bot' ? botState.name : 'You'}
                    <p className="conversation-time">{getRelativeTime(conversation.chat.createdAt)}</p>
                  </div>
                  <div className="message-description">
                  {conversation.chat.text==='create_ticket'?'Create Ticket':<DynamicContentRenderer htmlContent={truncateText(conversation.chat.text)} actionButtonHandler={()=>{}} /> }
                  </div>
                </div>
                <div className="message-arrow">  ➔ </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="mb-4 bg-white relative p-1" style={{bottom:'1rem'}}>
        <div className="relative flex justify-around">
          <button
            className="relative flex flex-col items-center text-gray-400 mb-1"
            onClick={() => handleClick("home")}
          >
            <div
              className={`w-6 h-6 ${
                active === "home" ? "text-blue-500" : "text-gray-400"
              }`}
            >
              <svg
                className="w-full h-full"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10 3L2 9h2v7h4V13h4v3h4V9h2L10 3z" />
              </svg>
            </div>
            <span className="text-sm">
              <div
                className={`${
                  active === "home" ? "text-blue-500" : "text-gray-400"
                }`}
              >
                Home
              </div>
            </span>
          </button>
          <button
            className="relative flex flex-col items-center text-gray-400 mb-1"
            onClick={() => handleClick("messages")}
          >
            <div
              className={`w-6 h-6 ${
                active === "messages" ? "text-blue-500" : "text-gray-400"
              }`}
            >
              <svg
                className="w-full h-full"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M2 3a2 2 0 012-2h12a2 2 0 012 2v12a2 2 0 01-2 2H4l-2 2V3z" />
              </svg>
            </div>
            <span className="text-sm">
              <div
                className={`${
                  active === "messages" ? "text-blue-500" : "text-gray-400"
                }`}
              >
                Messages
              </div>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;

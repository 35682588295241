import React, { useEffect, useRef, useState } from "react";
import "./ChatMessages.css";
import Header from "../Header/Header";
import {
  DEFAULT_LOGO_FOR_WEBCHAT,
  DEFAULT_USER_AVATAR,
} from "../../utils/constants";
import { useChatbotContext } from "../../context/ChatGlobalContext";
import { getSession } from "../../utils/helper";
import DynamicContentRenderer from "../shared/DynamicContentRenderer";

interface ChatMessageProps {
  backgroundColour: string;
  prevStep: () => void;
  prompt?: string;
}

interface Message {
  text: string;
  messageType: "user" | "bot";
  avatar: string;
  timestamp: string;
  content?: string;
  isLoading?: boolean;
}

const ChatMessages: React.FC<ChatMessageProps> = ({
  backgroundColour,
  prevStep,
  prompt,
}) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState("");
  const [isBotTyping, setIsBotTyping] = useState(false);

  const chatContainerRef = useRef<HTMLDivElement>(null);

  const { botState, socket } = useChatbotContext();
  const historyFetched = useRef(false);



  useEffect(() => {
    if (!socket) return;

    // Listen for incoming messages from the bot
    if (socket) {
      socket.on("enjo-query-response", handleBotMessage);

      socket.on("conversation-history", getHistory);
    }

    // Clean up on component unmount

    return () => {
      socket.off("enjo-query-response", handleBotMessage);

      socket.off("conversation-history", getHistory);
    };
  }, [socket]);

  useEffect(() => {
    getCurrentConversation();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (prompt) {
        handleBotMessage({ answer: prompt });
      }
      if (!prompt && botState?.initialMessage) {
        if (localStorage.getItem("conversationId")) return;
        handleBotMessage({ answer: botState?.initialMessage });
      }
    }, 300); // 300ms delay

    return () => clearTimeout(timer);
  }, [prompt, botState?.initialMessage]);

  const handleBotMessage = (message: any) => {
    console.log("Received message from bot:", message);

    if (message.conversationId)
      localStorage.setItem("conversationId", message.conversationId);
    if (message.session) localStorage.setItem("session", message.session);
    const botMessage: Message = {
      content: message.content,
      text: message.answer,
      messageType: "bot",
      avatar: botState?.logo || DEFAULT_LOGO_FOR_WEBCHAT,
      timestamp: new Date().toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }),
    };

    if (message.answer || message.content) {
      setMessages((prevMessages) => {
        // Filter out the previous bot message with non-empty content
        const filteredMessages = prevMessages.filter(
          (prevMessage) =>
            !(prevMessage.messageType === "bot" && prevMessage.content)
        );

        // Add the new bot message
        return [...filteredMessages, botMessage];
      });
      setIsBotTyping(false);
    }
  };

  const sendMessage = (message: string, prompt?: string) => {
    const userMessage: Message = {
      text: prompt ? prompt : message,
      messageType: "user",
      avatar: DEFAULT_USER_AVATAR,
      timestamp: new Date().toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }),
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setIsBotTyping(true); // Bot starts typing

    if (socket) {
      const session = getSession();
      const conversationId = localStorage.getItem("conversationId");
      let data: any = {
        text: prompt ? prompt : message,
        origin: window.location,
        messageFrom: "webchat",
        botId: botState?.botId,
        webchatId: botState?._id,
        from: socket.id,
        website: true,
        session: session,
      };
      if (conversationId) {
        data.conversationId = conversationId;
      }

      socket.emit("enjo-query-msg", data);
    }
  };

  const handleSend = () => {
    if (input.trim()) {
      sendMessage(input);
      setInput("");
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  useEffect(() => {
    // Scroll to the bottom of the chat container when messages change
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages.length]);

  const getHistory = (msg: any) => {
    if (historyFetched.current) return;
    const historyMessages: Message[] =
      msg?.chatHistories?.map((message: any) => {
        return {
          content: message.content,
          text: message.text,
          messageType: message.messageType === "user" ? "user" : "bot",
          avatar:
            message.messageType === "bot"
              ? botState?.logo || DEFAULT_LOGO_FOR_WEBCHAT
              : DEFAULT_USER_AVATAR,
          timestamp: new Date(message.timestamp).toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }),
        };
      }) || [];

    setMessages((prevMessages) => [...prevMessages, ...historyMessages]);
    historyFetched.current = true;
  };

  const getCurrentConversation = () => {
    const session = getSession();
    if (socket) {
      let data: { session: any; conversationId?: string; from: any } = {
        from: socket.id,
        session: session,
      };

      const conversationId = localStorage.getItem("conversationId");
      if (conversationId) {
        data = {
          ...data,
          conversationId: conversationId,
        };
      }

      socket.emit("get-conversation-history", data);
    }
  };

  const handleTicket = (e: any, index: number) => {
    if (e.action_id) {
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        updatedMessages[index] = {
          ...updatedMessages[index],
          isLoading: true, // Set loading state for the message at the given index
        };
        return updatedMessages;
      });

      if (socket) {
        const session = getSession();
        const conversationId = localStorage.getItem("conversationId");
        let data: any = {
          ...e,
          origin: window.location,
          messageFrom: "webchat",
          botId: botState?.botId,
          webchatId: botState?._id,
          from: socket.id,
          website: true,
          session: session,
          suggestTicketCreateMessage: botState?.suggestTicketCreateMessage,
        };
        console.log(data);
        if (conversationId) {
          data.conversationId = conversationId;
        }
        socket.emit("enjo-query-msg", data);
      }
    }
  };

  return (
    <>
      <Header onClick={prevStep} title={botState.name || "IT support"} />
      <div
        style={{ backgroundColor: backgroundColour }}
        className={`flex flex-col h-[90%] shadow-lg pb-8 `}
      >
        {/* Chat Messages */}
        <div
          ref={chatContainerRef}
          className="flex-1 overflow-auto p-4 webchat__wrapper-chatList"
        >
          {messages.map((message, index) => (
            <div
              key={index}
              className={`mb-4 flex ${
                message?.messageType === "user"
                  ? "justify-end"
                  : "justify-start"
              }`}
            >
              {message?.messageType === "bot" && (
                <div className="flex items-end message-container">
                  <img
                    src={message.avatar}
                    alt="Bot"
                    className="w-8 h-8 rounded-full mr-2"
                  />
                  <div
                    className={`p-3 rounded-xl max-w-xs border text-black relative`}
                  >
                    {message?.text && (
                      <DynamicContentRenderer
                        key="message.text"
                        htmlContent={message?.text}
                        actionButtonHandler={(e: any) => console.log(e)}
                      />
                    )}

                    {message?.content && (
                      <DynamicContentRenderer
                        htmlContent={message?.content}
                        isLoading={message.isLoading}
                        actionButtonHandler={(e) => handleTicket(e, index)}
                      />
                    )}
                    {/* <div className="timestamp text-xs text-gray-500 mt-1 absolute bottom-0 right-0">
                      {message?.timestamp}
                    </div> */}
                  </div>
                </div>
              )}
              {message?.messageType === "user" && (
                <div className="flex items-end message-container">
                  <div
                    className={`p-3 rounded-xl max-w-xs border text-black relative`}
                  >
                    {message?.text}
                    {/* <div className="timestamp text-xs text-gray-400 mt-1 absolute bottom-0 right-0">
                      {message?.timestamp}
                    </div> */}
                  </div>
                  <img
                    src={message.avatar}
                    alt="User"
                    className="w-8 h-8 rounded-full ml-2"
                  />
                </div>
              )}
            </div>
          ))}
          {isBotTyping && (
            <div className="mb-4 flex justify-start">
              <div className="flex items-end">
                <img
                  src={botState?.logo || DEFAULT_LOGO_FOR_WEBCHAT}
                  alt="Bot"
                  className="w-8 h-8 rounded-full mr-2"
                />
                <div className="p-3 rounded-xl max-w-xs border text-gray-500 text-sm">
                  <span className="typing">Bot is typing</span>
                  <span className="dot">.</span>
                  <span className="dot">.</span>
                  <span className="dot">.</span>
                  <span className="dot">.</span>
                  <span className="dot">.</span>
                  <span className="dot">.</span>
                </div>
              </div>
            </div>
          )}
        </div>

        {botState?.suggestedChatMessage?.messages.length > 0 && (
          <div className="prompt-container">
            <div className="chat-prompts">
              {botState?.suggestedChatMessage?.messages.map((message: any) => (
                <button
                  key={message._id}
                  onClick={() => sendMessage(message?.prompt)}
                  className="chat-prompt-button m-2 p-2 rounded-full text-white focus:outline-none"
                >
                  {message.title}
                </button>
              ))}
            </div>
          </div>
        )}

        {/* Input Field */}
        <div className="bg-gray-100 p-2 rounded-lg m-4">
          <div className="flex items-center rounded-md">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={handleKeyDown} // Added this line
              placeholder="Type message..."
              className="flex-1 p-2 border-none bg-gray-100 focus:outline-none focus:ring focus:border-blue-300 rounded-md"
            />
            <button
              onClick={handleSend}
              className="ml-2 p-2 text-white rounded-full focus:outline-none"
            >
              <svg
                className="h-6 w-8  text-gray-800"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                {" "}
                <line x1="22" y1="2" x2="11" y2="13" />{" "}
                <polygon points="22 2 15 22 11 13 2 9 22 2" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatMessages;

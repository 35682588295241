import React from 'react';
import  { ReactComponent as RightArrow } from "../../icons/arrow.svg"
import { useChatbotContext } from '../../context/ChatGlobalContext';

type StartConversationProps = {
  onClick: () => void;
  className?: string; // Add className prop
};

const StartConversation: React.FC<StartConversationProps> = ({ onClick, className }) => {
  const {botState} = useChatbotContext();
  return (
    <button 
      className={`w-full text-left p-4 rounded-md flex items-center justify-between`} 
      onClick={onClick}
    >
     {botState?.startConversationMessage||'Start a conversation'}  <RightArrow/>
    </button>
  );
};

export default StartConversation;
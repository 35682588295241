import React from "react";
import { useChatbotContext } from "../../context/ChatGlobalContext";
import { ReactComponent as DownArrow } from '../../icons/InterfacedownArrow.svg';
import "./ChatLauncher.css";

const ChatLauncher: React.FC = (): React.JSX.Element => {
  const { toggleChat, botState , chatVisible } = useChatbotContext();

  const side =
    botState?.launcherConfig?.launcherPosition === "left" ? "left" : "right";
  const customStyle = {
    [side]: botState?.launcherConfig?.sideSpacing||"40px",
    backgroundColor: botState?.themeColor || "blue",
    bottom: botState?.launcherConfig?.bottomSpacing||"40px",
  };

  const customStyleTitle = {
    [side]: botState?.launcherConfig?.sideSpacing+50||"40px",
    backgroundColor: botState?.themeColor || "blue",
    bottom: botState?.launcherConfig?.bottomSpacing||"40px",
  };
  return (
    <>
    {!chatVisible&&botState?.startConversationMessage&&
    <div  onClick={toggleChat} style={customStyleTitle} className="webchat__toggler_text" >{botState?.startConversationMessage}</div>
    }
    <div className="chat-launcher webchat__toggler" style={customStyle}>
      <button
        style={{ backgroundColor: botState?.themeColor || "blue" }}
        className="bg-blue-600 text-white p-4 rounded-full shadow-lg focus:outline-none"
        onClick={toggleChat}
      >
        {!chatVisible?<img
          src={
            botState?.launcherConfig?.logo ||
            "https://stage-trooprbucket.s3.ap-south-1.amazonaws.com/webchat-logo/Enjo+icon-+white.png"
          }
          alt=""
        />:<DownArrow style={{height:'20px' ,width:'20px'}} />}
      </button>
    </div>
    </>

  );
};

export default ChatLauncher;

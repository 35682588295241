import React, {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import io, { Socket } from "socket.io-client";
import { initializeBot } from "../utils/helper";

interface ChatbotContextType {
  botState: any;
  setBotState: (user: any) => void;
  chatVisible: boolean;
  toggleChat: () => void;
  socket: Socket | null;
}

const ChatbotContext = createContext<ChatbotContextType | undefined>(undefined);

export const ChatbotProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [chatVisible, setChatVisible] = useState(false);
  const [botState, setBotState] = useState({});
  const [hasFetched, setHasFetched] = useState(false);
  const [webchatId, setWebchatId] = useState(localStorage.getItem("webchatid"));
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get("webchatid");


  const socketRef = useRef<any>(null);

  const toggleChat = () => {
    setChatVisible(!chatVisible);
  };

  const getConfig = useCallback(async () => {
    if (hasFetched || !webchatId) return;
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/app.webchat.getWebchatConfig?webchatId=${webchatId}`
    );
    const data = await response.json();

    if (data && data.data) {
      initializeBot(data.data.botId);
      setBotState({ ...data.data, name: data.name, logo: data.logo });
      setHasFetched(true);
    }
  }, [hasFetched, webchatId]);

  useEffect(() => {
    getConfig();
  }, [getConfig]);

  useEffect(() => {
    if (id) {
      setWebchatId(id);
      localStorage.setItem("webchatid", id);
    }

    if (process.env.REACT_APP_ENVIRONMENT === "local") {
      socketRef.current = io("http://localhost:4001");
    } else {
      const domain = process.env.REACT_APP_DOMAIN;
      socketRef.current = io(`wss://${domain}`, {
        path: "/wsproxy",
      });
    }

    return () => {
      socketRef.current?.disconnect();
    };
  }, []);

  return (
    <ChatbotContext.Provider
      value={{
        chatVisible,
        toggleChat,
        botState,
        setBotState,
        socket: socketRef.current,
      }}
    >
      {children}
    </ChatbotContext.Provider>
  );
};

export const useChatbotContext = () => {
  const context = useContext(ChatbotContext);
  if (context === undefined) {
    throw new Error("useChatbotContext must be used within a ChatbotProvider");
  }
  return context;
};

export {};

import React from 'react';
import "./ChatBotFooter.css"
import { DEFAULT_LOGO_FOR_WEBCHAT } from '../../utils/constants';

const ChatBotFooter = () => {
  return (
    <div style={{ padding: "0.5rem" }} className="footer bg-[#EAF1FC] rounded">
      <p className="footer-sec">
        Powered by{" "}
        <img
          className="footer-img"
          src={DEFAULT_LOGO_FOR_WEBCHAT}
          alt="footer icon"
        ></img>{" "}
        <span className="footer-enjo">Enjo</span>
      </p>
    </div>
  );
};

export default ChatBotFooter;

export const getSession = () => {
  const session = JSON.parse(localStorage.getItem("session") as string);
  if (session) return session;
  else return null;
};

export const truncateText = (input: string = '', maxLength: number = 60): string=>{
  if (input.includes('<')) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = input;

    // Replace <a> tags with <span> tags
    tempDiv.querySelectorAll('a').forEach(anchor => {
      const span = document.createElement('span');
      span.innerHTML = anchor.innerHTML;
      anchor.replaceWith(span);
    });

    // Remove <button> tags
    tempDiv.querySelectorAll('button').forEach(button => button.remove());

    // Truncate text inside <p> tag
    const pTag = tempDiv.querySelector('p');
    if (pTag) {
      const textContent = pTag.textContent || '';
      if (textContent.length > maxLength) {
        pTag.textContent = textContent.substring(0, maxLength) + '...';
      }
    }

    return tempDiv.innerHTML;
  } else {
    // Handle plain text
    return input.length > maxLength ? input.substring(0, maxLength) + '...' : input;
  }
}



export const initializeBot =(botId:string) => {
  const storedBotId = localStorage.getItem('botId');
  if (storedBotId !== botId) {
      localStorage.removeItem('session')
      localStorage.removeItem('botId')
      localStorage.removeItem('conversationId')
      localStorage.setItem('botId', botId);
  } else {
      console.log('BotId is the same. No changes made to local storage.');
  }
}


export const getRelativeTime = (timestamp:string) => {
  const now:any = new Date();
  const date:any = new Date(timestamp);
  const diff = now - date;

  const minutes = Math.floor(diff / 1000 / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (minutes < 1) {
      return 'just now';
  } else if (minutes === 1) {
      return '1 min ago';
  } else if (minutes < 60) {
      return `${minutes} mins ago`;
  } else if (hours === 1) {
      return '1 hr ago';
  } else if (hours < 24) {
      return `${hours} hrs ago`;
  } else if (days === 1) {
      return 'yesterday';
  } else {
      return `${days} days ago`;
  }
}


export const capitalizeFirstLetter =(text:string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
}


export const getUserName =  () => {
  const session = getSession();
  const userEmail = session?.metadata?.userDetails?.email || ''
  let username = session?.metadata?.userDetails?.name ? session?.metadata?.userDetails?.name : userEmail.split('@')[0];
  username = capitalizeFirstLetter(username);
  return username;
}
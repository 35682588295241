import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import "./ChatContainer.css";
import ChatBotFooter from "../ChatBotFooter/ChatBotFooter";
import PersonalDetailsForm from "../PersonalDetails/PersonalDetails";
import ChatMessages from "../ChatMessages/ChatMessages";
import Home from "../Home/Home";
import { useChatbotContext } from "../../context/ChatGlobalContext";

const ChatContainer: React.FC = () => {
  const { chatVisible ,botState } = useChatbotContext();
  const navigate = useNavigate();
  const [prompt ,setPrompt] =useState("");


  const side =
    botState?.launcherConfig?.launcherPosition === "left" ? "left" : "right";
  const customStyle = {
    [side]: botState?.launcherConfig?.sideSpacing||"40px",
    bottom: botState?.launcherConfig?.bottomSpacing +100||"140px",
    height: 'calc(95%)'
  };

  const [isSessionAvailable, setIsSessionAvailable] = useState(false);

  const nextStep = (text?:string) => {
   
    if (isSessionAvailable) {
      if(text){
        setPrompt(text);
      }else{
        setPrompt('');
      } 
      navigate("/chat");
    } else {
      navigate("/details");
    }
  };

  const prevStep = () => {
    localStorage.removeItem('conversationId')
    if (isSessionAvailable) {
      navigate("/");
    } else {
      navigate(-1);
    }
  };

  const onFormSubmit = (msg: any) => {
    setPrompt('');
    navigate("/chat");
  };

  useEffect(() => {
    if (localStorage.getItem('session')) {
      const session = JSON.parse(localStorage.getItem('session') as string);
      if (session) {
        setIsSessionAvailable(true);
      }
    } else {
      setIsSessionAvailable(false);
    }

  }, []);

  return (
    <div
      style={customStyle}
      className={` webchat__wrapper rounded bg-white  shadow-lg  overflow-hidden ${
        !chatVisible ? "hidden" : ""
      }`}
    >
      <Routes>
        <Route path="/"  element={<Home nextStep={nextStep} />} />
        <Route
          path="/details"
          element={
            <PersonalDetailsForm onSubmit={onFormSubmit} prevStep={prevStep} />
          }
        />
        <Route
          path="/chat"
          element={
            <ChatMessages
              prevStep={prevStep}
              prompt={prompt}
              backgroundColour={""}
            />
          }
        />
      </Routes>
      {botState?.showEnjoBranding && <ChatBotFooter />}
    </div>
  );
};;


export default ChatContainer;
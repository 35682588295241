import './App.css';
import ChatLauncher from './components/ChatLauncher/ChatLauncher';
import ChatContainer from './components/ChatContainer/ChatContainer';
import {ChatbotProvider} from './context/ChatGlobalContext';
import { BrowserRouter as Router}  from "react-router-dom";
const App = () => {

  return (
    <>
      <ChatbotProvider  >
        <ChatLauncher  />
        <Router >
      <ChatContainer   />
        </Router>
      </ChatbotProvider>
    </>
  );
};

export default App;





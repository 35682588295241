import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import Header from '../Header/Header';
import { useChatbotContext } from '../../context/ChatGlobalContext';

interface FormData {
    name: string;
    phone: string;
    email: string;
    countryCode: string;

}

interface PersonalDetailsFormProps {
    onSubmit: (formData: FormData) => void;
    prevStep: () => void;
}

const PersonalDetailsForm: React.FC<PersonalDetailsFormProps> = ({ onSubmit ,prevStep  }) => {
    const [formData, setFormData] = useState<FormData>({
        name: '',
        phone: '',
        email: '',
        countryCode:'',
    });
    const  { botState ,socket}  = useChatbotContext()

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
         if(socket){
           let message = {
            action_id: "user_contact_form_details",
            payload: { ...formData},
            origin: window.location,
            messageFrom: "webchat",
            botId: botState?.botId,
            webchatId: botState?._id,
            from: socket.id,
            website: true,
          };   
          socket.emit("enjo-query-msg", message);
          
        }

     


    };

    useEffect(() => {
        if (!socket) return;
    
        // Listen for incoming messages from the bot
        socket.on("contact-form-submit", handleFormSubmission);
    
        // Clean up on component unmount
        return () => {
          socket.off("enjo-query-response", handleFormSubmission);
        };
      }, [socket]);


      const handleFormSubmission = (msg:any) => {
        localStorage.setItem('session', JSON.stringify(msg.session));
        onSubmit(msg)
      }

    const isMandatory = botState?.contactFormConfig?.mandatorySubmission;

    return (
        <>
        <Header onClick={prevStep} title={''} />
          <div className="flex-1 p-6 h-auto">
            <h2 className="text-sm text-gray-800 font-semibold mb-4">Please fill the form to continue</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              {botState?.contactFormConfig?.countryCode&&<div>
                    <label className="block text-sm font-medium text-gray-700"><span className='text-red-500 pr-1'>*</span>Country Code</label>
                    <input 
                        type="text" 
                        name="countryCode" 
                        value={formData.countryCode} 
                        onChange={handleChange} 
                        className="mt-1 p-2 block w-full border-gray-300 bg-input-box-color rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" 
                        placeholder="+91" 
                        required={isMandatory}

                    />
                </div>}
                {botState?.contactFormConfig?.name&&<div>
                    <label className="block text-sm font-medium text-gray-700"><span className='text-red-500 pr-1'>*</span>Name</label>
                    <input 
                        type="text" 
                        name="name" 
                        value={formData.name} 
                        onChange={handleChange} 
                        className="mt-1 p-2 block w-full border-gray-300 bg-input-box-color rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" 
                        placeholder="cactus" 
                        required={isMandatory}

                    />
                </div>}
                {botState?.contactFormConfig?.phoneNumber&&<div>
                    <label className="block text-sm font-medium text-gray-700"><span className='text-red-500 pr-1'>*</span>Phone Number</label>
                    <input 
                        type="text" 
                        name="phone" 
                        value={formData.phone} 
                        onChange={handleChange} 
                        className="mt-1 p-2 block w-full border-gray-300 bg-input-box-color  rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" 
                        placeholder="0123456789" 
                        required={isMandatory}

                    />
                </div>}
                {botState?.contactFormConfig?.email&&<div >
                    <label className="block text-sm font-medium text-gray-700"><span className='text-red-500 pr-1'>*</span>Email</label>
                    <input 
                        type="email" 
                        name="email" 
                        value={formData.email} 
                        onChange={handleChange} 
                        className="mt-1 p-2 block w-full border-gray-300 bg-input-box-color  rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" 
                        placeholder="cactus@gmail.com" 
                        required={isMandatory}
                        />
                </div>} 
                <div>
                <button
                     type="submit" 
                     className="mt-8 bg-indigo-600 text-white p-3 rounded-lg w-full flex justify-between items-center hover:bg-indigo-700">
                        <div className='w-full flex justify-between items-center px-4'>
                        <span>Submit</span>
                        <span className="flex items-center">➤</span>
                        </div>       
                </button>
                </div>
            </form>
        </div>
        </>
      
    );
};

export default PersonalDetailsForm;

import React, { useLayoutEffect, useRef } from 'react';

interface DynamicContentProps {
  htmlContent: string;
  actionButtonHandler: (params: { action_id: string; chatHistoryId: string; text?:string, payload?: any }) => void;
  isLoading?: boolean;
}

const DynamicContentRenderer: React.FC<DynamicContentProps> = ({ htmlContent, actionButtonHandler, isLoading = false }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const container = containerRef.current;
    if (container) {
      // Create a temporary container to process the HTML content
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = processMessageContent(htmlContent);

      const handleClick = (event: Event) => {
        const target = event.target as HTMLElement;
        const chatbox: any = document.querySelector('.webchat__wrapper-chatList');

        if (target.classList.contains('read-more')) {
          event.preventDefault();
          const messageSpan = target.closest('div')?.querySelector('.message-content') as HTMLElement;
          const fullMessageSpan = target.closest('div')?.querySelector('.full-message') as HTMLElement;
          const readLessLink = target.closest('div')?.querySelector('.read-less') as HTMLElement;

          if (messageSpan && fullMessageSpan && readLessLink) {
            messageSpan.style.display = 'none';
            fullMessageSpan.style.display = 'inline';
            target.style.display = 'none';
            readLessLink.style.display = 'inline';
            chatbox.scrollTo(0, chatbox.scrollHeight);
          }
        } else if (target.classList.contains('read-less')) {
          event.preventDefault();
          const messageSpan = target.closest('div')?.querySelector('.message-content') as HTMLElement;
          const fullMessageSpan = target.closest('div')?.querySelector('.full-message') as HTMLElement;
          const readMoreLink = target.closest('div')?.querySelector('.read-more') as HTMLElement;

          if (messageSpan && fullMessageSpan && readMoreLink) {
            messageSpan.style.display = 'inline';
            fullMessageSpan.style.display = 'none';
            target.style.display = 'none';
            readMoreLink.style.display = 'inline';
            chatbox.scrollTo(0, chatbox.scrollHeight);
          }
        }
      };

      container.addEventListener('click', handleClick);

      const buttonsWithOnClick = tempDiv.querySelectorAll('button[onclick]');
      buttonsWithOnClick.forEach((button) => {
        const onClickAttr = button.getAttribute('onclick');
        if (onClickAttr) {
          const actionIdMatch = onClickAttr.match(/action_id:\s*'([^']+)'/);
          const chatHistoryIdMatch = onClickAttr.match(/chatHistoryId:\s*'([^']+)'/);
          const textMatch = onClickAttr.match(/text :\s*'([^']+)'/);

          const action_id = actionIdMatch ? actionIdMatch[1] : '';
          const chatHistoryId = chatHistoryIdMatch ? chatHistoryIdMatch[1] : '';
          const text = textMatch ? textMatch[1] : '';

          // Store parameters in data attributes
          (button as HTMLElement).dataset.actionId = action_id;
          (button as HTMLElement).dataset.chatHistoryId = chatHistoryId;
          (button as HTMLElement).dataset.text = text;

          if (isLoading) {
            (button as any).disabled = true;
            (button as HTMLElement).innerText = 'Loading...';
          }

          // Remove the inline onclick attribute
          button.removeAttribute('onclick');
        }
      });

      // Process forms with onsubmit attributes
      const formsWithOnSubmit = tempDiv.querySelectorAll('form[onsubmit]');
      formsWithOnSubmit.forEach((form) => {
        const onSubmitAttr = form.getAttribute('onsubmit');
        if (onSubmitAttr) {
          const actionIdMatch = onSubmitAttr.match(/action_id:\s*'([^']+)'/);
          const chatHistoryIdMatch = onSubmitAttr.match(/chatHistoryId:\s*'([^']+)'/);
          const action_id = actionIdMatch ? actionIdMatch[1] : '';
          const chatHistoryId = chatHistoryIdMatch ? chatHistoryIdMatch[1] : '';

          // Store parameters in data attributes
          (form as HTMLElement).dataset.actionId = action_id;
          (form as HTMLElement).dataset.chatHistoryId = chatHistoryId;

          const submitButton = form.querySelector('button[type="submit"]') as HTMLButtonElement;
          submitButton.disabled = true; // Initially disable submit button

          const checkFormValidity = () => {
            const allFieldsFilled = Array.from(form.querySelectorAll('input')).every(input => input.value.trim() !== '');
            submitButton.disabled = !allFieldsFilled; // Enable button if all fields are filled
          };

          form.addEventListener('input', checkFormValidity); // Attach input listener to validate fields

          if (submitButton && isLoading) {
            submitButton.disabled = true;
            submitButton.innerText = 'Loading...';
          }
          // Remove the inline onsubmit attribute
          form.removeAttribute('onsubmit');
        }
      });

      // Clear and append the processed content to the container
      container.innerHTML = '';
      container.appendChild(tempDiv);

      // Attach event listeners to buttons
      const buttons = container.querySelectorAll('button');
      buttons.forEach((button) => {
        button.addEventListener('click', (event) => {
          const targetButton = event.currentTarget as HTMLElement;
          const action_id = targetButton.dataset.actionId || '';
          const chatHistoryId = targetButton.dataset.chatHistoryId || '';
          const text = targetButton.dataset.text || '';

          // Call the actionButtonHandler with extracted parameters
          actionButtonHandler({ action_id, chatHistoryId, text });
        });
      });

      // Attach event listeners to forms
      const forms = container.querySelectorAll('form');
      forms.forEach((form) => {
        form.addEventListener('submit', (event) => {
          event.preventDefault();
          const targetForm = event.currentTarget as HTMLFormElement;
          const action_id = targetForm.dataset.actionId || '';
          const chatHistoryId = targetForm.dataset.chatHistoryId || '';

          // Extract form data
          const formData = new FormData(targetForm);
          const formObject: any = {};
          formData.forEach((value, key) => {
            formObject[key] = value;
          });

          // Call the actionButtonHandler with formData
          actionButtonHandler({ action_id, chatHistoryId, payload: { ...formObject } });
        });
      });
    }
  }, [htmlContent, actionButtonHandler, isLoading]);

  return <div ref={containerRef}></div>;
};

export default DynamicContentRenderer;



const processMessageContent = (messageContent: string): string => {
  // Pattern to match custom links like <link|description>
  const customLinkPattern = /<(\S+?)\|(.+?)>/g;

  // Check if the pattern matches the content
  if (customLinkPattern.test(messageContent)) {
    messageContent = messageContent.replace(
      customLinkPattern,
      '<a class="link-style" href="$1" target="_blank">$2</a>'
    );
  } else {
    // Markdown-style link pattern [text](url)
    const markdownPattern = /\[([^\]]+)\]\((https?:\/\/[^)]+)\)/ig;
    messageContent = messageContent.replace(
      markdownPattern,
      '<a class="link-style" href="$2" target="_blank">$1</a>'
    );

    // Plain URL pattern
    // const urlPattern = /\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|]/ig;
    // messageContent = messageContent.replace(urlPattern, (url: string) => {
    //   console.log(url);
    //   if (!messageContent.includes(`<a href="${url}"`)) {
    //     const formattedURL = url.length > 30 ? url.substring(0, 30) + '...' : url;
    //     return `<a class="link-style" href="${url}" target="_blank">${formattedURL}</a>`;
    //   } else {
    //     return url;
    //   }
    // });
  }


  const createTicketPattern = new RegExp('<header class="ticket-header"')

  const containsTicketHeader =createTicketPattern.test(messageContent);


  if(containsTicketHeader)return messageContent
  


  // Create a temporary div to handle the HTML manipulation
  const tempElement = document.createElement('div');

  tempElement.innerHTML = messageContent;

  const salesforceFormPattern = new RegExp('<form id="');
  const salesforceFormPatternValid = salesforceFormPattern.test(messageContent);




  // Extract the plain text content to apply truncation logic

  
  const textContent = tempElement.textContent || tempElement.innerText || "";
  const truncatedMessageContent = textContent?.trim()?.length > 250 
    ? textContent.substring(0, 250) + '...' 
    : messageContent;


  // Detect if the message contains specific header pattern
  // Create a new div for the final message content

  const newMessage = document.createElement('div');


  
  
  const messageHtml = salesforceFormPatternValid
    ? messageContent
    : `
      <div>
        <pre class="message-content" style="white-space: pre-wrap;">${truncatedMessageContent}</pre>
        ${
          textContent.trim().length > 250
            ? '<a class="read-more">Read More</a>'
            : ""
        }
        ${
          textContent.trim().length > 250
            ? '<pre class="full-message" style="display:none; white-space: pre-wrap;">' +
              messageContent +
              '</pre><a href="#" class="read-less" style="display:none;">Read Less</a>'
            : ""
        }
      </div>`;



  
  newMessage.innerHTML = messageHtml;

  if (textContent?.trim()?.length > 250) {

  // Attach event listeners for Read More/Less functionality
  const readMoreLink:any= newMessage.querySelector('.read-more');
  const readLessLink:any = newMessage.querySelector('.read-less');
  const messageSpan:any = newMessage.querySelector('.message-content');
  const fullMessageSpan:any = newMessage.querySelector('.full-message');

  if (readMoreLink && readLessLink) {
    readMoreLink.addEventListener('click', (e: MouseEvent) => {
      e.preventDefault();
      messageSpan.style.display = 'none';
      readMoreLink.style.display = 'none';
      fullMessageSpan.style.display = 'inline';
      readLessLink.style.display = 'inline';
    });

    readLessLink.addEventListener('click', (e: MouseEvent) => {
      e.preventDefault();
      messageSpan.style.display = 'inline';
      readMoreLink.style.display = 'inline';
      fullMessageSpan.style.display = 'none';
      readLessLink.style.display = 'none';
    });
  }
}

  // Return the final processed HTML
  return newMessage.outerHTML;
};
